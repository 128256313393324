<template>
    <div>
        <h2>ช่องทางการชำระ</h2>
        
        <div>
          <v-btn-toggle
            v-model="selectedPage"
            tile
            color="primary"
            group
          >
            <v-btn value="bank" @click="$router.push({ name: 'payment' })">
              โอนผ่านบัญชีธนาคาร
            </v-btn>

            <v-btn value="gbp" @click="$router.push({ name: 'payment_beam' })">
              บัตรเครดิต/ผ่อน/QR
            </v-btn>            
          </v-btn-toggle>
        </div>        

        <v-card class="mt-4 px-6 pt-6">
            <v-text-field
              outlined
              v-model="datatable.search"
              placeholder="ค้นหา ..."
              dense
            ></v-text-field>
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <!--
            <v-btn              
              @click="deleteMultiple()"
              :disabled="datatable.selectedItems.length == 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>        
            -->
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'payment_create', params: { payment_id: 'create' } })">เพิ่มบัญชีธนาคาร</v-btn>
          </div>
          <delay-datatable
            :headers="datatable.headers"
            :url="'/setting/payment/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template          
            v-slot:item.bank="{ item }"
          >   
            {{ item.info.bank ? item.info.bank.name : '-' }}
          </template>

          <template          
            v-slot:item.accountNo="{ item }"
          >   
            {{ item.info ? item.info.accountNo : '-' }}
          </template>
          
          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'payment_create', params: { payment_id: item.id }})"
            ><v-icon>{{ $store.getters['auth/GET_ABILITY'].can('update', 'Setting') ? icons.mdiPlaylistEdit : icons.mdiEyeOutline }}</v-icon></v-btn>
            <v-btn
              icon
              @click="deleteSingle(item.id, item.name)"
              :disabled="!$store.getters['auth/GET_ABILITY'].can('delete', 'Setting')"
            ><v-icon>{{ icons.mdiDeleteOutline }}</v-icon></v-btn>
          </template>
          </delay-datatable>
        </v-card>        
    </div>
</template>
<script>
import { asyncDelete } from '@/helpers/asyncAxios'
import { convertChannelTypeToImage, convertChannelTypeToText } from '@/helpers/converter.js'
import { required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiEyeOutline, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      selectedPage: 'bank',
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'ชื่อช่องทาง',
            value: 'name',
          },
          {
            text: 'ธนาคาร',
            value: 'bank',
          },
          {
            text: 'เลขบัญชี',
            value: 'accountNo',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      deleteDialog: {
        show: false,
        loading: false,
        formValid: false,
        targetName: null,
        targetId: null,
      },
      icons: {
        mdiDeleteOutline,
        mdiPlaylistEdit,
        mdiEyeOutline,
      },
      helpers: {
        convertChannelTypeToText,
        convertChannelTypeToImage,
      },
      validators: {
        required,
      },
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        search: this.datatable.search,
      }
    },
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/setting/payment', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
  components: {},
}
</script>